@tailwind base;
@tailwind components;
@tailwind utilities;

.categories {
  @apply flex-auto inline-block align-middle p-3 text-center hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded
}

.buttonStyle {
  @apply bg-gradient-to-r from-impact-blue to-impact-teal text-white hover:animate-bounce rounded m-4 py-4 px-8 font-semibold shadow-xl
}